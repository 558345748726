<template>
  <main class="h-screen flex flex-1 justify-center items-center relative ">
    <div
      @click="$router.go(-1)"
      class="flex cursor-pointer items-center absolute left-5 top-5"
    >
      <div class="p-2 bg-gray-800 py-3 px-6 rounded-md text-white">
        <svg
          class="w-5 h-5 fill-current text-blue-500"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2541 4.24106C16.5522 4.53326 16.5793 4.99051 16.3354 5.31272L16.2541 5.40503L9.52658 12L16.2541 18.595C16.5522 18.8872 16.5793 19.3444 16.3354 19.6666L16.2541 19.7589C15.956 20.0511 15.4896 20.0777 15.161 19.8386L15.0668 19.7589L7.7459 12.582C7.44784 12.2898 7.42074 11.8325 7.66461 11.5103L7.7459 11.418L15.0668 4.24106C15.3947 3.91965 15.9262 3.91965 16.2541 4.24106Z"
          />
        </svg>
      </div>
      <!-- <div class="ml-2 text-sm text-black">Back</div> -->
    </div>
    <div class=" max-w-3xl w-full shadow-sm border-2 p-10 border-gray-300">
      <h1 class=" font-bold text-center  ">
        Set Charge Fees
      </h1>
      <input
        type="number"
        min="0"
        onkeyup="if(this.value<0)this.value=1"
        onblur="if(this.value<0)this.value=1"
        class="mt-8 border w-full  ring-2 ring-blue-700 rounded-md py-4 pl-3 border-gray-300 border-2"
        v-model="value"
        v-if="edit"
      />
      <div
        v-else
        class="mt-8 border w-full    rounded-md py-4 pl-3 border-gray-300 border-2"
      >
        {{ value }} Naira
      </div>
      <div class="mt-8 flex justify-end items-end">
        <button
          @click="edit = !edit"
          class="mr-4 border py-4 bg-gray-700 text-white px-6 rounded-md"
        >
          {{ !edit ? "Edit" : "Back" }}
        </button>
        <button
          @click="updateTransferFee"
          v-if="edit"
          class=" border py-4 bg-blue-700 text-white px-6 rounded-md"
        >
          Update
        </button>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      value: null,
      edit: false,
    };
  },
  methods: {
    async makeRequest() {
      let res = await this.$store.dispatch("GET_CURRENT_TRANSFER_FEE");
      this.value = res.data;
    },
    async updateTransferFee() {
      let value = +this.value || 0;
      let res = await this.$store.dispatch("UPDATE_FEE", { value });
      if (res.status) {
        this.value = res.data;
        this.edit = false;
        alert("Fee Changed Succesfully");
      } else {
        alert(res.data || "There was a problem");
      }
    },
  },
  async created() {
    await this.makeRequest();
  },
};
</script>

<style></style>
